<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'
import Form from './components/form.vue'
import SideButtons from '../../../../../../components/side-buttons.vue';
import Swal from "sweetalert2";
export default {
    page: {
        title: "Liste des Déchèts et Ditritus",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components:{
        Layout,PageHeader,
        Dataset,
        DatasetItem,
        DatasetPager,
        DatasetShow,
        Form,
        SideButtons
    },
    data() {
        return {
            title:"Liste des Déchèts et Ditritus",
            activities : [],
            active:false,
            add:false,
            edit:false,
            destroy:false,
            resetForm:false,
            canEdit:false,
            save:false,
            selectedRow:[],
            selectedIndex:-1,
            items: [
                {
                    text: "HSE",
                    to: {name:"service_catalog.hse"},
                },
                {
                    text: "Déchèts et Ditritus",
                    to: {name:"service_catalog.hse.trashes"},
                },
                {
                    text: "Liste",
                    active: true,
                },
            ],
            cols: [
                    {
                    name: 'ID',
                    field: 'id',
                    sort: ''
                    },
                    {
                    name: 'REF',
                    field: 'ref',
                    sort: ''
                    },
                    {
                    name: 'Base de vie',
                    field: 'lifebase',
                    sort: ''
                    },
                    {
                    name: 'Date',
                    field: 'contract',
                    sort: ''
                    },
                    {
                    name: 'Status',
                    field: 'supplier',
                    sort: ''
                    },
                    {
                    name: 'Fait par',
                    field: 'date_ods',
                    sort: ''
                    },
                    {
                    name: 'Validé par',
                    field: 'date_exec_',
                    sort: ''
                    }
                
                ],

        }
    },
    mounted(){
      this.activitiesList();
    },
    watch:{
      save:{
        handler(val){
          if(val){
            this.activitiesList();
            this.selectedRow = [];
            this.selectedIndex = -1;
          }
        },
        destroy:{
          handler(val){
            if(val && this.$can('delete_activity')){
              this.deleteActivity()
            }
            else this.$toast.error('Vous n\'avez la permission requise pour cette action');
          }
        }
      }
    },
    methods:{
      selectRow(row,index){
        if(this.selectedIndex == index) {this.selectedRow = []; this.selectedIndex = -1;this.canEdit=false}
        else {
          this.selectedRow = [row];
          this.selectedIndex = index;
          if(row.status != ('validated' || 'rejected')) this.canEdit = true;
          if(!this.$can('edit_trash')) this.canEdit=false;
        }
      },
      activitiesList(){
        this.$http.post('/ops/hse/trashes/list')
        .then(res => {
          this.save = false;
          this.activities = res.data;
        })
      },
      deleteActivity(){
        var _this = this;
            Swal.fire({
                title: 'Êtes-vous sûr?',
                text: "Cette action est irreversible!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui'
                }).then((result) => {
                if (result.isConfirmed) {
                    _this.$http.post('/ops/hse/trashes/delete/'+_this.selectedRow[0].uuid)
                    .then(res => {
                        _this.selectedRow = []; 
                        _this.selectedIndex = -1;
                        _this.canEdit=false;
                        _this.destroy=false;
                        _this.resetForm=true;
                        _this.activitiesList();
                        Swal.fire('Réussi!','Activité supprimé','success')
                    })
                    .catch(error => console.log(error))
                }
                else _this.destroy=false;
            })
      }
    }
}
</script>
<template>
    <Layout>
    <PageHeader :title="title" :items="items"  />
    <SideButtons :canEdit="!canEdit" :ODS="false" :reset="resetForm"  @add="add = $event"  @edit=" edit = $event" 
                @resetEmit="resetForm = !$event" @delete=" destroy = $event" />
    <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des actions réalisées</div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
                <div class=" mb-0 shadow">
                      <dataset
                        v-slot="{ ds }"
                        :ds-data="activities"
                      >
                        <div class="row">
                          <div class="col-md-12">
                            <div class="">
                              <table class="table table-hover d-md-table">
                                <thead>
                                  <tr>
                                    <th v-for="(th) in cols" :key="th.field">
                                      {{ th.name }} 
                                    </th>
                                  </tr>
                                </thead>
                                <dataset-item tag="tbody">
                                  <template #default="{ row, rowIndex }">
                                    <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                                      <th>
                                        {{ rowIndex + 1 }}
                                      </th>
                                      <th>
                                        {{ row.ref }}
                                      </th>
                                      <td>{{ row.camp.name }}</td>
                                      <td>{{ row.exec_date }}</td>
                                      <td>
                                        <span v-if="row.status == 'draft'" class="badge badge-secondary">Brouillon</span>
                                        <span v-if="row.status == 'pending'" class="badge badge-warning">En cours de confimration</span>
                                        <span v-if="row.status == 'confirmed'" class="badge badge-info">Confirmé</span>
                                        <span v-if="row.status == 'approved'" class="badge badge-success">Validé</span>
                                        <span v-if="row.status == 'rejected'" class="badge badge-danger">Rejeté</span>
                                      </td>
                                      <td><span class="badge badge-info">{{ row.made_by ? row.made_it_by.firstName : "N/A"}}</span></td>
                                      <td><span class="badge badge-info">{{ row.valid_by ? row.validate_by.firstName : "N/A" }}</span></td>
                                      <!-- <td>
                                        <button v-if="row.status =='approved'" class="btn btn-primary" @click="printSingleDocument('single_rest_so', row.uuid)" >
                                          <i class="fas fa-print"></i>
                                        </button>
                                      </td> -->
                                    </tr>
                                  </template>
                                </dataset-item>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                          <dataset-show :ds-show-entries="5" />
                          <dataset-pager />
                        </div>
                      </dataset>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
       
        <div class="card mt-3">
          <Form :add="add" :edit="edit" @resetForm="resetForm = $event" :activity="selectedRow" @saved="save = $event"></Form>
        </div>
      </div>
    </div>
    </Layout>
</template>